import styled from "styled-components";
import '../global-styles/reset.css';
import '../global-styles/main.css';

const PageWrapper = styled.div`

    .section {
        padding-top: 50px;
        padding-bottom: 25px;    
    }

    .dark {
        background: #575043;
        color: white;
        h1 {
            color: white;
        }
    }

    .container {
        width: 100%;
        min-height: 25px;
        margin: 0 auto;
        h1, p {
            padding: 0 15px;
        }
    }

    .half {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 50px;
        h1, p {
            padding: 0 15px;
        }
        .text-pad {
            margin-bottom: 25px;
        }
    }

    .img-responsive {
        width: 100%;
        display: block;
        border: 0;
    }

    .cover-image {
        width: 100%;
        height: 100%;
        max-height: 765px;
        min-height: 370px;
        background-size: cover;            
        background-position-y: center;
        background-position-x: center;
    }

    /* For mobile phones: */
    [class*="col-"] {
        width: 100%;
    }

    .image-row-container {
        display: flex;
        flex-wrap: wrap;
        height: unset;
    }
    .col-3 { width: 50%; }


    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {}

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {}

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .section {
            padding-top: 75px;
            padding-bottom: 50px;    
        }
        .container {
            width: 750px;
            h1, p {
                padding: 0;
            }
        }
        .image-row-container {
            display: flex;
            height: 370px;
        }
        .half {
            width: calc(750px + (100% - 750px) /2 );
            margin-left: calc((100% - 750px) / 2);
            display: flex;
            flex-direction: row;
            padding-top: 0;
            .text-pad {
                padding-right: 75px;
                width: calc(50% - 75px);
                min-height: 760px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        .cover-image  {
            max-height: unset;
        }



        [class*="col-"] {
            display: inline-block;
        }

        /* For desktop: */
        .col-1 {width: 8.33%;}
        .col-2 {width: 16.66%;}
        .col-3 {width: 25%;}
        .col-4 {width: 33.33%;}
        .col-5 {width: 41.66%;}
        .col-6 {width: 50%;}
        .col-7 {width: 58.33%;}
        .col-8 {width: 66.66%;}
        .col-9 {width: 75%;}
        .col-10 {width: 83.33%;}
        .col-11 {width: 91.66%;}
        .col-12 {width: 100%;}

    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .container {
            width: 980px;
        }
        .half {
            width: calc(980px + (100% - 980px) /2 );
            margin-left: calc((100% - 980px) / 2);
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .container {
           width: 1180px;
        }
        h2 {
            max-width: 1180px;
            margin-left: auto;
            margin-right: auto;
        }
        .half {
            width: calc(1180px + (100% - 1180px) /2 );
            margin-left: calc((100% - 1180px) / 2);
        }

    }

    /* Extra Extra large devices (large laptops and desktops, 1600px and up) */
    @media only screen and (min-width: 1620px) {
        .container {
           width: 1600px;
        }
        .half {
            width: calc(1600px + (100% - 1600px) /2 );
            margin-left: calc((100% - 1600px) / 2);
        }

    }

`;

export default PageWrapper;